<template>
  <div class="row m-0 p-0 pt-1">
    <div class="col-12 m-0 p-0" v-if="isInputType">
      <input :type="type" :disabled="disabled" class="form-control" :class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand', disabled ? 'disabled' : '']" :min="min" :placeholder="inputValue" v-model="inputValue" :required="!optional">
      <!-- <datepicker :input-class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand', 'form-control']" :calendar-class="[background !== 'light' ? 'bg_darkest' : 'bg_light']" :value="inputValue" @selected="changeDate($event)"></datepicker> -->
    </div>
    <div class="col-12 m-0 p-0" v-else-if="type === 'textarea'">
      <textarea rows="4" class="form-control" :disabled="disabled" :class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand', disabled ? 'disabled' : '']" :placeholder="inputValue" v-model="inputValue" :required="!optional"></textarea>
    </div>
    <div class="col-12 m-0 p-0" v-else-if="type === 'radiobutton'">
      <div class="row m-0 p-0">
        <div class="col-12" :class="[disabled ? 'disabled' : '']" v-for="(ans, i) of answers" :key="i">
          <input type="radio" :disabled="disabled" :id="index + '_' + i" :value="ans.value" v-model="inputValue" class="ml-3">
          <label :for="index + '_' + i" class="pl-2">{{ans.text}}</label>
        </div>
      </div>
    </div>
    <div class="col-12 m-0 p-0" v-else-if="type === 'select'">
      <v-select
            :class="{'select-transparent': background !== 'light'}"
            label="text"
            :options="answers"
            v-model="inputValue"
            :clearable="false"
            :reduce="item => item.value"
            :disabled="disabled"
            :filter="filterDDLTextStartsWith"
          />
    </div>
    <div class="col-12 m-0 p-0" v-else>
      {{type}}
    </div>
  </div>
</template>
<script>
// import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  name: 'FormElement',
  props: {
    background: {
      value: String,
      default: null
    },
    type: {
      value: String,
      default: null,
      required: true
    },
    optional: {
      value: Boolean,
      default: true
    },
    inputData: {
      value: [String, Number, Object, Array],
      default: null
    },
    answers: {
      value: Array,
      default: null
    },
    index: {
      value: [String, Number],
      default: null
    },
    disabled: {
      value: Boolean,
      default: false
    },
    min: {
      value: Number,
      default: null
    },
    max: {
      value: Number,
      default: null
    }
  },
  components: {
    // Datepicker,
    vSelect
  },
  created () {
    this.inputValue = this.inputData
  },
  data () {
    return {
      inputValue: null
    }
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ]),
    isInputType () {
      const arr = ['text', 'number', 'date', 'time']
      return arr.includes(this.type)
    }
  },
  watch: {
    inputValue: function () {
      this.$emit('updated', this.inputValue)
    },
    inputData: function (newVal) {
      this.inputValue = newVal
    }
  },
  methods: {
    changeDate (e) {
      this.inputValue = e
    },
    filterDDLTextStartsWith (options, search) {
      return options.filter((option) => {
        let label = option ? option.text : ''
        if (typeof label === 'number') {
          label = label.toString()
        }
        return (label || '').toLowerCase().startsWith(search.toLowerCase())
      })
    }
  }
}
</script>
<style>
.disabled {
  cursor: not-allowed;
}
</style>
